import { dateTime } from '@grafana/data';
import $ from 'jquery';
import kbn from 'grafana/app/core/utils/kbn';
// @ts-ignore
import { getBackendSrv, getDataSourceSrv } from '@grafana/runtime';

import _ from 'lodash';
import { GetColorForValue, GetColorIndexForValue, StringToJsRegex } from './Utils';
import 'datatables.net';
import 'mark.js';
import 'datatables.mark.js';
import 'datatables.net-buttons'
import JSZip from 'jszip';
//import moment from 'moment';

export class DatatableRenderer {
  formatters: any;
  colorState: any;
  panel: any;
  table: any;
  isUtc: boolean;
  sanitize: any;
  timeSrv: any;
  dataSourceSrv: any;
  backendSrv: any;
  headers: any;
  q: any;
  downloadData: any[] = [];
  nextDownloadData: any;

  // from app/core/constants
  GRID_CELL_HEIGHT = 30;
  // from inspect
  TITLE_LINE_HEIGHT = 28;
  constructor(panel: any, table: any, isUtc: boolean, sanitize: any, timeSrv: any, headers: any, q: any) {
    this.formatters = [];
    this.colorState = {};
    this.panel = panel;
    this.table = table;
    this.isUtc = isUtc;
    this.sanitize = sanitize;
    this.timeSrv = timeSrv;
    this.dataSourceSrv = getDataSourceSrv();
    this.backendSrv = getBackendSrv();
    this.headers = headers;
    this.q = q;
  }

  /**
   * Formats a cell
   * @param v value
   * @param style style obj
   * @param column column
   * @param rowIndex The row index
   * @return any formatted data
   */
  defaultCellFormatter(v: any, style: any, column: any, rowIndex: number) {
    if (v === null || v === void 0 || v === undefined || column === null) {
      return '';
    }
    if (_.isArray(v)) {
      v = v.join(', ');
    }
    v = String(v);

    // coerce style to an object
    if (typeof style === 'undefined' || typeof style !== 'object') {
      style = {};
    }
    let cellTemplate = style.url;

    if (typeof style.splitPattern === 'undefined' || style.splitPattern === '') {
      style.splitPattern = '/ /';
    }

    const regex = StringToJsRegex(String(style.splitPattern));
    const values = v.split(regex);
    if (typeof cellTemplate !== 'undefined') {
      // replace $__from/$__to/$__
      cellTemplate = this.replaceTimeMacros(cellTemplate);
      // Replace $__cell with this cell's content.
      cellTemplate = cellTemplate.replace(/\$__cell\b/, v);
      values.map((val: any, i: any) => (cellTemplate = cellTemplate.replace(`$__pattern_${i}`, val)));
    }

    if (style && style.sanitize) {
      return this.sanitize(v);
    } else if (style && style.link && cellTemplate && column.text === style.column) {
      const matches = /\$__cell_(\d+)/g.exec(cellTemplate);
      // start with the template
      let linkValue = cellTemplate;
      if (matches) {
        // index zero is the whole string
        for (let matchIndex = 1; matchIndex < matches.length; matchIndex++) {
          //console.log(`rowIndex: ${rowIndex} matchIndex: ${matchIndex}`);
          const matchedCellNumber = parseInt(matches[matchIndex], 10);
          if (!isNaN(matchedCellNumber)) {
            const matchedCellContent = this.table.rows[rowIndex][matchedCellNumber];
            //console.log(`matchedCellNumber: ${matchedCellNumber} matchedCellContent: ${matchedCellContent}`);
            linkValue = linkValue.replace(`$__cell_${matchedCellNumber}`, matchedCellContent);
          }
        }
        const valueFormatter = kbn.valueFormats[column.unit || style.unit];
        if (style && style.decimals) {
          v = valueFormatter(v, style.decimals, null);
        } else {
          v = valueFormatter(v);
        }
        return '<a href="' + linkValue + '" target="_blank">' + v + '</a>';
      } else {
        const linkValue = cellTemplate.replace(/\{\}|\$__cell_\d*/g, v);
        return '<a href="' + linkValue + '" target="_blank">' + v + '</a>';
      }
      return _.escape(v);
    } else if (style && style.link) {
      return '<a href="' + v + '" target="_blank">' + v + '</a>';
    } else {
      return _.escape(v);
    }
  }

  // Similar to DataLinks, this replaces the value of the panel time ranges for use in url params
  replaceTimeMacros(content: string) {
    let newContent = content;
    if (content.match(/\$__from/g)) {
      // replace all occurences
      newContent = newContent.replace('$__from', this.timeSrv.time.from);
    }
    if (content.match(/\$__to/g)) {
      // replace all occurences
      newContent = newContent.replace('$__to', this.timeSrv.time.to);
    }
    if (content.match(/\$__keepTime/g)) {
      // replace all occurences
      const keepTime = `from=${this.timeSrv.time.from}&to=${this.timeSrv.time.to}`;
      newContent = newContent.replace('$__keepTime', keepTime);
    }
    return newContent;
  }
  /**
   * [createColumnFormatter description]
   * @param  {[type]} style  [description]
   * @param  {[type]} column [description]
   * @return {[type]}        [description]
   */
  createColumnFormatter(style: any, column: any) {
    if (!style) {
      return this.defaultCellFormatter;
    }
    if (style.type === 'hidden') {
      return (v: any, rIndex: number) => {
        return null;
      };
    }
    if (style.type === 'date') {
      return (v: any, rIndex: number) => {
        if (v === undefined || v === null) {
          return '-';
        }

        if (_.isArray(v)) {
          v = v[0];
        }

        // if is an epoch (numeric string and len > 12)
        if (_.isString(v) && !isNaN(v as any) && v.length > 12) {
          v = parseInt(v, 10);
        }

        let date = dateTime(v);

        if (this.isUtc) {
          date = date.utc();
        }
        const fmt = date.format(style.dateFormat);
        return fmt;
      };
    }
    if (style.type === 'number') {
      const valueFormatter = kbn.valueFormats[column.unit || style.unit];
      return (v: any, rIndex: number) => {
        if (v === null || v === void 0) {
          return '-';
        }
        if (_.isString(v)) {
          return this.defaultCellFormatter(v, style, column, rIndex);
        }
        if (style.colorMode) {
          this.colorState[style.colorMode] = GetColorForValue(v, style);
        }
        return valueFormatter(v, style.decimals, null);
      };
    }
    if (style.type === 'string') {
      return (v: any, rIndex: number) => {
        if (_.isArray(v)) {
          v = v.join(', ');
        }

        const mappingType = style.mappingType || 0;

        if (mappingType === 1 && style.valueMaps) {
          for (let i = 0; i < style.valueMaps.length; i++) {
            const map = style.valueMaps[i];
            if (v === null) {
              if (map.value === 'null') {
                return map.text;
              }
              continue;
            }

            // Allow both numeric and string values to be mapped
            if ((!_.isString(v) && Number(map.value) === Number(v)) || map.value === v) {
              return this.defaultCellFormatter(map.text, style, column, rIndex);
            }
          }
        }

        if (mappingType === 2 && style.rangeMaps) {
          for (let i = 0; i < style.rangeMaps.length; i++) {
            const map = style.rangeMaps[i];
            if (v === null) {
              if (map.from === 'null' && map.to === 'null') {
                return map.text;
              }
              continue;
            }

            if (Number(map.from) <= Number(v) && Number(map.to) >= Number(v)) {
              return this.defaultCellFormatter(map.text, style, column, rIndex);
            }
          }
        }

        if (v === null || v === void 0) {
          return '-';
        }
        return this.defaultCellFormatter(v, style, column, rIndex);
      };
    }
    return (v: any, rIndex: number) => {
      return this.defaultCellFormatter(v, style, column, rIndex);
    };
  }

  /**
   * [formatColumnValue description]
   * @param  {[type]} colIndex [description]
   * @param  {[type]} rowIndex [description]
   * @param  {[type]} value    [description]
   * @return {[type]}          [description]
   */
  formatColumnValue(colIndex: any, rowIndex: any, value: any) {
    if (!this.formatters[colIndex]) {
      for (let i = 0; i < this.panel.styles.length; i++) {
        const style = this.panel.styles[i];
        const column = this.table.columns[colIndex];
        const regex = StringToJsRegex(style.pattern);
        if (column.text.match(regex)) {
          this.formatters[colIndex] = this.createColumnFormatter(style, column);
        }
      }
    }
    if (!this.formatters[colIndex]) {
      this.formatters[colIndex] = this.defaultCellFormatter;
    }
    let v = this.formatters[colIndex](value, rowIndex);
    if (/\$__cell_\d+/.exec(v)) {
      for (let i = this.table.columns.length - 1; i >= 0; i--) {
        v = v.replace(`$__cell_${i}`, this.table.rows[rowIndex][i]);
      }
    }
    return v;
  }

  /**
   * [generateFormattedData description]
   * @param  {[type]} rowData [description]
   * @return {[type]}         [description]
   */
  generateFormattedData(rowData: any) {
    const formattedRowData = [];
    for (let y = 0; y < rowData.length; y++) {
      const row = this.table.rows[y];
      const cellData = [];
      for (let i = 0; i < this.table.columns.length; i++) {
        let value = this.formatColumnValue(i, y, row[i]);
        if (value === undefined || value === null) {
          this.table.columns[i].hidden = true;
        }
        if (value === null) {
          value = row[i];
        }
        const record = {
          display: value,
          raw: row[i],
          _: row[i],
        };
        cellData.push(record);
      }
      if (this.panel.rowNumbersEnabled) {
        cellData.unshift('rowCounter');
      }
      formattedRowData.push(cellData);
    }
    return formattedRowData;
  }

  getStyleForColumn(columnNumber: any) {
    let colStyle = null;
    for (let i = 0; i < this.panel.styles.length; i++) {
      const style = this.panel.styles[i];
      const column = this.table.columns[columnNumber];
      if (column === undefined) {
        break;
      }
      const regex = StringToJsRegex(style.pattern);
      if (column.text.match(regex)) {
        colStyle = style;
        break;
      }
    }
    return colStyle;
  }

  getCellColors(colorState: any, columnNumber: any, cellData: any) {
    if (cellData === null || cellData === undefined) {
      return null;
    }
    const items = cellData.split(/([^0-9.,]+)/);
    // only color cell if the content is a number?
    let bgColor = null;
    let bgColorIndex = null;
    let color = null;
    let colorIndex = null;
    let colStyle = null;
    let value = null;
    // check if the content has a numeric value after the split
    if (!isNaN(items[0])) {
      // run value through threshold function
      value = parseFloat(items[0].replace(',', '.'));
      colStyle = this.getStyleForColumn(columnNumber);
    }
    if (colStyle !== null && colStyle.colorMode != null) {
      // check color for either cell or row
      if (colorState.cell || colorState.row || colorState.rowcolumn) {
        // bgColor = _this.colorState.cell;
        bgColor = GetColorForValue(value, colStyle);
        bgColorIndex = GetColorIndexForValue(value, colStyle);
        color = 'white';
      }
      // just the value color is set
      if (colorState.value) {
        //color = _this.colorState.value;
        color = GetColorForValue(value, colStyle);
        colorIndex = GetColorIndexForValue(value, colStyle);
      }
    }
    return {
      bgColor: bgColor,
      bgColorIndex: bgColorIndex,
      color: color,
      colorIndex: colorIndex,
    };
  }

  getColumnAlias(columnName: any) {
    // default to the columnName
    let columnAlias = columnName;
    if (this.panel.columnAliases !== undefined) {
      for (let i = 0; i < this.panel.columnAliases.length; i++) {
        if (this.panel.columnAliases[i].name === columnName) {
          columnAlias = this.panel.columnAliases[i].alias;
          break;
        }
      }
    }
    return columnAlias;
  }

  getColumnWidthHint(columnName: any) {
    // default to the columnName
    let columnWidth = '';
    if (this.panel.columnWidthHints !== undefined) {
      for (let i = 0; i < this.panel.columnWidthHints.length; i++) {
        if (this.panel.columnWidthHints[i].name === columnName) {
          columnWidth = this.panel.columnWidthHints[i].width;
          break;
        }
      }
    }
    return columnWidth;
  }

  getColumnRenderStyle(data: any, row: any) {
    let colStyle = data;
    //const _this = this;
    for (let i = 0; i < this.panel.styles.length; i++) {
      if (this.panel.styles[i].pattern === row) {
        if(this.panel.styles[i].type === 'date'){
          //colStyle = data ? moment.unix(data).format(_this.panel.styles[i].dateFormat) : '';
        }
      }
    }
    return colStyle;
  }

  /**
   * Construct table using Datatables.net API
   *  multiple types supported
   * timeseries_to_rows (column 0 = timestamp)
   * timeseries_to_columns
   * timeseries_aggregations - column 0 is the metric name (series name, not a timestamp)
   * annotations - specific headers for this
   * table
   * json (raw)
   * columns[x].type === "date" then set columndefs to parse the date, otherwise leave it as default
   * convert table.columns[N].text to columns formatted to datatables.net format
   * @return {[Boolean]} True if loaded without errors
   */
  render() {
    $.fn.dataTable.ext.errMode = 'none';
    const tableHolderId = '#datatable-panel-table-' + this.panel.id;
    try {
      if ($.fn.dataTable.isDataTable(tableHolderId)) {
        const aDT = $(tableHolderId).DataTable();
        aDT.destroy();
        $(tableHolderId).empty();
      }
    } catch (err: any) {
      console.log('Exception: ' + err.message);
    }

    const columns = [];
    const columnDefs = [];
    const _this = this;
    let rowNumberOffset = 0;
    if (this.panel.rowNumbersEnabled) {
      rowNumberOffset = 1;
      columns.push({
        title: '',
        type: 'number',
      });
      columnDefs.push({
        searchable: false,
        orderable: false,
        targets: 0,
        width: '1%',
      });
    }

    for (let i = 0; i < this.headers.length; i++) {
      columns.push({
        name: i,
        title: this.headers[i],
        render: function (data: any, type: any, row: any, meta: any) {
          return _this.getColumnRenderStyle(data, i);
        }
      })
    }

    try {
      let shouldDestroy = false;
      if ($.fn.dataTable.isDataTable('#datatable-panel-table-' + this.panel.id)) {
        shouldDestroy = true;
      }
      if (shouldDestroy) {
        const destroyedDT = $('#datatable-panel-table-' + this.panel.id).DataTable();
        destroyedDT.destroy();
        $('#datatable-panel-table-' + this.panel.id).empty();
      }
    } catch (err: any) {
      console.log('Exception: ' + err.message);
    }

    let panelHeight = this.panel.panelHeight;
    if (this.panel.scroll) {
      if (typeof this.panel.height === 'undefined') {
        panelHeight = this.getGridHeight(this.panel.gridPos.h);
      }
    } else {
      panelHeight = '';
    }
    const orderSetting = this.panel.sortByColumnsData;

    let selectSettings: DataTables.SelectSettings;
    selectSettings = {
      style: 'os',
    };
    //    scrollY: panelHeight.toString() + 'px',
    const tableOptions = {
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, 'All'],
      ],
      searching: this.panel.searchEnabled,
      info: this.panel.infoEnabled,
      lengthChange: this.panel.lengthChangeEnabled,
      scrollCollapse: false,
      scrollX: true,
      scrollY: panelHeight,
      stateSave: false,
      mark: this.panel.searchHighlightingEnabled || false,
      dom: 'rtilBp',
      select: selectSettings,
      /* data: formattedData,*/
      columns: columns,
      /*columnDefs: columnDefs,*/
      // TODO: move search options to editor
      search: {
        regex: true,
        smart: false,
      },
      order: orderSetting,
      scroll: this.panel.scroll,
      paging: !this.panel.scroll,
      pagingType: this.panel.datatablePagingType,
      processing: true,
      serverSide: true,
      ajax: {
        url: this.dataSourceSrv.settingsMapByName[_this.panel.datasource].url,
        data: function(d: any) {
          _this.panel.targets[0].params.map((item: any) => {
            // @ts-ignore
            //eval("d." + item[0] + " = '" + _this.dataSourceSrv.templateSrv.replace(item[1]) + "'")
          });
          return d;
        }
      },
      buttons: [
        {
          text: 'Descargar en CSV',
          action: function (e: any, dt: any, node: any, config: any) {
            const separator = ',';
            let zipFile: JSZip = new JSZip();
            let url = _this.dataSourceSrv.settingsMapByName[_this.panel.datasource].url;
            let i = 0;
            _this.nextDownloadData = true;
            const iteration = 500;
            dt.ajax.params()['length'] = iteration;
            _this.downloadData.push(_this.headers.join(separator));
            do {
              dt.ajax.params()['start'] = i;
              $.ajax({
                url: url + '?' + $.param(dt.ajax.params()) + '&_=1636077879657',
                method:  'GET',
                async:   false,
                cache:   false
              })
              .done(function(response: any){
                _this.downloadData.push(response.data.join("\n"));
                if(response.data.length < iteration) {
                  _this.nextDownloadData = false;
                }
              });
              i = i + iteration;
            } while (_this.nextDownloadData === true);
            zipFile.file("report.csv", _this.downloadData.join("\n"));

            zipFile.generateAsync({
              type: 'blob',
              streamFiles: true,
              compression: "DEFLATE",
              compressionOptions: {
                level: 9
              }
            },
            function updateCallback(metadata) {
              console.log("progression: " + metadata.percent.toFixed(2) + " %");
              if(metadata.currentFile) {
                console.log("current file = " + metadata.currentFile);
              }
            }
            )
            .then(function(content: any) {
              saveAs(content, "report.zip");
            });
          }
        }
      ],
    };

    const $datatable = $(tableHolderId);
    // @ts-ignore
    const newDT = $datatable.DataTable(tableOptions);

    // hide columns that are marked hidden
    for (let i = 0; i < this.panel.styles.length; i++) {
      if (this.panel.styles[i].type === 'hidden') {
        newDT.column(i + rowNumberOffset).visible(false);
      }
    }

    // enable compact mode
    if (this.panel.compactRowsEnabled) {
      $datatable.addClass('compact');
    }
    // enable striped mode
    if (this.panel.stripedRowsEnabled) {
      $datatable.addClass('stripe');
    }
    if (this.panel.hoverEnabled) {
      $datatable.addClass('hover');
    }
    if (this.panel.orderColumnEnabled) {
      $datatable.addClass('order-column');
    }
    // these two are mutually exclusive
    if (this.panel.showCellBorders) {
      $datatable.addClass('cell-border');
    } else {
      if (this.panel.showRowBorders) {
        $datatable.addClass('row-border');
      }
    }
    if (!this.panel.scroll) {
      // set the page size
      if (this.panel.rowsPerPage !== null) {
        newDT.page.len(this.panel.rowsPerPage).draw();
      }
    }
    // function to display row numbers
    if (this.panel.rowNumbersEnabled) {
      newDT
        .on('order.dt search.dt', () => {
          newDT
            .column(0, { search: 'applied', order: 'applied' })
            .nodes()
            .each((cell, i) => {
              cell.innerHTML = i + 1;
            });
        })
        .draw();
    }

    if (this.panel.columnFiltersEnabled) {
      // @ts-ignore
      const header = newDT.table(0).header();
      //New additions to be put under a switch
      const newHeaders = $(header)
        .children('tr')
        .clone();
      newHeaders
        .find('th')
        .removeClass()
        .addClass('column-filter');
      newHeaders.appendTo(header as Element);
      $(header)
        .find(`tr:eq(1) th`)
        .each(function(i) {
          var title = $(this).text();
          $(this).html('<input class="column-filter" type="text" placeholder="Search ' + title + '" />');

          $('input', this).on('keyup change', function(this: any) {
            if (newDT.column(i).search() !== this.value) {
              newDT
                .column(i)
                .search(this.value)
                .draw();
            }
          });
        });
    }
  }

  getGridHeight(height: number) {
    const gridHeight = Math.ceil(height * this.GRID_CELL_HEIGHT) - this.TITLE_LINE_HEIGHT - 30;
    return gridHeight;
  }

  // For CSV Export
  render_values() {
    const rows = [];

    for (let y = 0; y < this.table.rows.length; y++) {
      const row = this.table.rows[y];
      const newRow = [];
      for (let i = 0; i < this.table.columns.length; i++) {
        newRow.push(this.formatColumnValue(i, y, row[i]));
      }
      rows.push(newRow);
    }
    return {
      columns: this.table.columns,
      rows: rows,
    };
  }
}
